/*
Name: Livly
Version: 0.0
*/

:root {
  --earth: #af5643;
  --felix: #262b2b;
  --felix-75: rgba(38, 43, 43, 0.75);
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('ui/webfonts/Roboto-BoldItalic.woff2') format('woff2'),
    url('ui/webfonts/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('ui/webfonts/Roboto-Medium.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('ui/webfonts/Roboto-ThinItalic.woff2') format('woff2'),
    url('ui/webfonts/Roboto-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('ui/webfonts/Roboto-BlackItalic.woff2') format('woff2'),
    url('ui/webfonts/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('ui/webfonts/Roboto-MediumItalic.woff2') format('woff2'),
    url('ui/webfonts/Roboto-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('ui/webfonts/Roboto-Italic.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('ui/webfonts/Roboto-Thin.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
    url('ui/webfonts/Roboto-Light.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black'), local('Roboto-Black'),
    url('ui/webfonts/Roboto-Black.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('ui/webfonts/Roboto-LightItalic.woff2') format('woff2'),
    url('ui/webfonts/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('ui/webfonts/Roboto-Bold.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
    url('ui/webfonts/Roboto-Regular.woff2') format('woff2'),
    url('ui/webfonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NoeDisplay-Bold';
  src: url('ui/webfonts/NoeDisplay-Bold.ttf');
}

@font-face {
  font-family: 'NoeDisplay-Black';
  src: url('ui/webfonts/NoeDisplay-Black.ttf');
}

/**
    Core Styles
**/

html,
body {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  background: #fafafa;
  min-height: 100%;
  height: 100%;
}

body {
  overscroll-behavior: none;
}

#root,
.App {
  min-height: 100%;
  height: 100%;
}

i + input {
  margin-left: 6px;
}

a {
  text-decoration: none;
  color: inherit;
}

.initial-text-transform {
  text-transform: initial !important;
}

/* The double classnames below are a specificity hack to make sure this overrides the MUI classname */
.subtext-primary.subtext-primary {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--felix);
}

.subtext-secondary.subtext-secondary {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--felix-75);
}

.disclaimer-primary.disclaimer-primary {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--felix-75);
}

.disclaimer-secondary.disclaimer-secondary {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: var(--felix-75);
}

.icon.error {
  color: var(--earth);
}

.fullscreen .react-images__blanket {
  z-index: 1500;
}
.fullscreen .react-images__positioner {
  z-index: 1500;
}
