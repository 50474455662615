.fade-enter {
  opacity: 0;
  z-index: 1;
  position: absolute;
}

.fade-enter.fade-enter-active {
  top: 0;
  opacity: 1;
  z-index: 2;
  transition: opacity 250ms ease-in;
}
